import moment from 'moment';
import numeral from 'numeral';
import getNumberFormats from '@/helpers/numberFormats';

const numberFormats = getNumberFormats();

/**
 * Helper function to format data coming from the Insights API
 * for external use
 *
 * @param {object} stats
 */
const formatStats = (stats) => {
    const newStats = {};

    let value;
    for (let stat in stats) {
        value = stats[stat];

        let formatted;

        if (typeof value == 'string') {
            formatted = value;
        } else if (stat === 'projectedSpendDifference') {
            formatted = (value !== null) ? numeral(value).format(numberFormats.percent) : null;
        } else {
            value = (value !== null) ? Math.round(value * 100) / 100 : null;
            formatted = (value !== null) ? numeral(value).format(numberFormats.currency) : null;
        }

        newStats[stat] = { value, formatted };
    }

    return newStats;
};


export const calculateTiming = () => {
    const now = moment();
    const startOfMonth = now.clone().startOf('month');
    const daysInMonth = now.daysInMonth();
    const daysPassedInMonth = now.diff(startOfMonth, 'days');
    const daysRemainingInMonth = daysInMonth - daysPassedInMonth;

    return {
        now,
        startOfMonth,
        daysInMonth,
        daysPassedInMonth,
        daysRemainingInMonth,
    };
};

/**
 * The primary function to compute the budgeting algorithms for
 * each ad account based on the timings and provided data
 *
 * @param {object} params
 */
export const calculateBudgeting = ({ monthlyBudget = null, spendToDate, dailyBudget, todaysSpend }) => {
    const {
        daysPassedInMonth,
        daysRemainingInMonth,
    } = calculateTiming();

    const spendToYesterday = (daysPassedInMonth === 0) ? 0 : (spendToDate - todaysSpend);

    // All values are required for calculations
    if (!spendToDate || !dailyBudget) {
        return formatStats({
            monthlyBudget,
            spendToDate,
            dailyBudget,
            todaysSpend,
            spendToYesterday,

            // NULL all computed metrics
            projectedSpend: null,
            averageDailyBudget: null,
            suggestedDailyBudget: null,
            suggestedDailyAdjustment: null,
            projectedSpendDifference: null,
        });
    }

    // Calculate the projected spend based on yesterday's spend and the number of days left
    // here we assume that the daily budget will continue spending
    const projectedSpend = spendToYesterday + (dailyBudget * daysRemainingInMonth);

    // Calculate the average for user reference.  If we're on the 1st day just use today's budget.
    const averageDailyBudget = (daysPassedInMonth >= 1) ? (spendToYesterday / daysPassedInMonth) : dailyBudget;

    // If there's no monthly budget assume the consumer just wants a projection
    if (!monthlyBudget) {
        // Return a usable object for consumption
        return formatStats({
            monthlyBudget,
            projectedSpend,
            spendToDate,
            dailyBudget,
            todaysSpend,
            spendToYesterday,
            averageDailyBudget,
            // Null values since no adjustment is possible without monthly budget
            suggestedDailyBudget: null,
            suggestedDailyAdjustment: null,
            activeModel: null,
            projectedSpendDifference: null,
        });
    }

    // Over "Suggested Daily Budget" (SDB) model - this is necessary because we've already spent
    // more than we were supposed to today so we need to stop spending today and decelerate the
    // budget going forward
    //
    // See original model explaining this here:
    // https://docs.google.com/spreadsheets/d/1L5s_s3WL9AJ5WJWe0F6dhWCoANrP4G_NJDFaBcKvMxo/edit#gid=824819648
    const daysRemainingMinusToday = daysRemainingInMonth - 1;
    const overSdbModelBudgetRemaining = monthlyBudget - spendToDate;
    const overSdbModelSuggestedDailyBudget = overSdbModelBudgetRemaining / daysRemainingMinusToday; // NaN on last day of month but not used

    // Underpacing (standard) model
    const defaultBudgetRemaining = monthlyBudget - spendToYesterday;
    const defaultDailyBudget = defaultBudgetRemaining / daysRemainingInMonth;

    let suggestedDailyBudget = null;
    let activeModel = null;

    // If we're not on the last day of the month and the overSdbModel budget remaining
    // is over today's spend use the overSdbModel model
    if ((daysRemainingMinusToday !== 0) && (overSdbModelSuggestedDailyBudget < todaysSpend)) {
        activeModel = 'over_sdb_model';
        suggestedDailyBudget = overSdbModelSuggestedDailyBudget;
    } else {
        activeModel = 'default';
        suggestedDailyBudget = defaultDailyBudget;
    }

    // Calculate the necessary adjustment for user reference
    // this is useful for finding budgets that are far off from target
    const suggestedDailyAdjustment = suggestedDailyBudget - dailyBudget;

    const projectedSpendDifference = (projectedSpend - monthlyBudget) / projectedSpend;

    // Return a usable object for consumption
    return formatStats({
        monthlyBudget,
        projectedSpend,
        spendToDate,
        dailyBudget,
        todaysSpend,
        spendToYesterday,
        averageDailyBudget,
        suggestedDailyBudget,
        suggestedDailyAdjustment,
        activeModel,
        projectedSpendDifference,
    });
};

export default calculateBudgeting;
