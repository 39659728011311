import cache from '@/apis/cache';
import ApiRepository from '@/api-repository';
import moment from 'moment';
import calculateBudgeting from './calculateBudgeting';
import {
    BUDGET_LEVEL_ADSET,
    PLATFORM_FACEBOOK,
    PLATFORM_PINTEREST,
    PLATFORM_STATUS_ACTIVE,
} from '@/helpers/globals';

const apiRepository = new ApiRepository();

/**
 * Gets the Campaigns for a specific Platform based on start and end date and filters them out based on the same params.
 * @param startDate
 * @param endDate
 * @param dealerId
 * @param platform
 * @returns {Promise<*>}
 */
const getPlatformCampaigns = async(startDate, endDate, dealerId, platform, filter) => {
    const response = await apiRepository.getDealerPlatformCampaigns({
        dealerId,
        platform,
        params: {
            start_date: startDate,
            end_date: endDate,
            stats: true,
            ...(platform === PLATFORM_PINTEREST && { disable_proxy_api: true }),
            ...(platform === PLATFORM_FACEBOOK && { filtering: filter }),
        },
    });

    return filterCampaigns(response, platform);
};

/**
 * Gets the Ad Sets for a specific campaign and filter them out as well.
 * @param dealerId
 * @param startDate
 * @param endDate
 * @param platform
 * @param campaignId
 * @returns {Promise<*>}
 */
const getCampaignAdSets = async(dealerId, startDate, endDate, platform, campaignId) => {
    const response = await apiRepository.getDealerPlatformAdSets({
        dealerId,
        platform,
        params: {
            start_date: startDate,
            end_date: endDate,
            stats: true,
            campaignIds: [campaignId],
        },
    });

    return filterAdSets(response, campaignId, platform);
};

/**
 * Gets the budget value for a campaign, defaults to 0 if no daily_budget. Currently, lifetime and other types of
 * budget are not supported.
 * @param campaign
 * @returns {number|*}
 */
const getBudgetValueFromCampaign = (campaign) => {
    if (campaign?.budget?.daily_budget) {
        return campaign?.budget?.daily_budget;
    }

    return 0;
};

/**
 * Retrieves the total daily budget given an array of campaigns
 * with (optional) adsets depending on how budgeting is configured
 *
 * @param {Number} current The current total
 * @param {Object} item A campaign or adset object
 * @returns {Number}  The total daily budget
 */
const reduceDailyBudget = (current, item) => {
    if (!shouldUseObjectInSpendProjections(item)) {
        return current;
    }

    // If adsets were added (which happens when adset budgeting is active)
    if (item.adsets?.length > 0) {
        const adSetBudgetTotal = item.adsets.reduce(reduceDailyBudget, 0);

        const hasActiveSpendCap = (
            (item.budget.daily_spend_cap > 0) &&
            (item.budget.daily_spend_cap < adSetBudgetTotal)
        );

        const effectiveBudget = hasActiveSpendCap ? item.budget.daily_spend_cap : adSetBudgetTotal;

        return current + effectiveBudget;
    }

    return current + getBudgetValueFromCampaign(item);
};

export const isLifetimeBudget = (budget) => !budget?.daily_budget && budget?.lifetime_budget;

/**
 * Sums the spend of campaigns
 * @param accumulator
 * @param current
 * @returns {*}
 */
const reduceSpend = (accumulator, current) => {
    if (!current.ad_set_id && current.budget.level === BUDGET_LEVEL_ADSET && current.adsets?.length) {
        return current.adsets.reduce(reduceSpend, accumulator);
    }

    if (current?.stats && !isLifetimeBudget(current?.budget)) {
        return accumulator + parseFloat(current?.stats?.spend || 0);
    }

    return accumulator;
};

/**
 * An object will be used on spend projects (e.g. projected spend) if they:
 * 1. Have their end date year higher than the year we are in
 * 2. Are in the year we are in and their month is higher or equal than the month we are in
 * 3. Are in the year we are in, their month is higher or equal than the month we are in and their day is equal
 * or higher than the day we are in
 *
 * @param object
 */
const shouldUseObjectInSpendProjections = (object) => {
    if (object?.status !== PLATFORM_STATUS_ACTIVE) {
        return false;
    }

    if (!object?.budget?.end_date) {
        return true;
    }

    const objectEndDate = new Date(object?.budget?.end_date);
    const now = new Date();

    const endDateYearIsHigherThanNow = objectEndDate.getFullYear() > now.getFullYear();
    const endDateYearIsEqualWithNow = objectEndDate.getFullYear() === now.getFullYear();

    return endDateYearIsHigherThanNow
        || endDateYearIsEqualWithNow && objectEndDate.getMonth() > now.getMonth()
        || endDateYearIsEqualWithNow && objectEndDate.getMonth() === now.getMonth() && objectEndDate.getDate() >= now.getDate();
};

/**
 * An object will be used on the spendToDate metric if they:
 * 1. Have their end date year higher than the year we are in
 * 2. They are in the year we are in and their month is higher or equal than the month we are in
 *
 * @param spend
 * @param endDate
 * @param platform
 * @returns {boolean}
 */
const shouldUseObjectInSpendToDate = (spend, endDate, platform) => {
    if (platform === PLATFORM_FACEBOOK && !spend) {
        return false;
    }

    if (!endDate) {
        return true;
    }

    const objectEndDate = new Date(endDate);
    const now = new Date();

    const endDateYearIsHigherThanNow = objectEndDate.getFullYear() > now.getFullYear();

    return endDateYearIsHigherThanNow
        || objectEndDate.getMonth() >= now.getMonth() && objectEndDate.getFullYear() === now.getFullYear();
};

/**
 * Filters campaigns by their end date and also by their status
 * @param campaigns
 * @param platform
 * @returns {*}
 */
const filterCampaigns = (campaigns, platform) => {
    const dataObject = safelyGetDataObject(campaigns);

    return dataObject.filter(campaign => shouldUseObjectInSpendToDate(campaign?.stats?.spend, campaign?.budget?.end_date, platform));
};

/**
 * Filters Ad Sets by their end date, status and if they belong to the campaignId passed as a param
 * @param adSets
 * @param campaignId
 * @param platform
 * @returns {*}
 */
const filterAdSets = (adSets, campaignId, platform) => {
    const dataObject = safelyGetDataObject(adSets);

    return dataObject.filter(adSet => adSet.campaign_id === campaignId
        && shouldUseObjectInSpendToDate(adSet?.stats?.spend, adSet?.budget?.end_date, platform));
};

const safelyGetDataObject = (object) => object?.data?.data ?? object?.data;

/**
 *
 * @param {Object} config
 * @param {Number} config.dealerId
 * @param {String} config.platform
 * @param {String} config.adAccount
 * @param {Number} config.monthlyBudget
 * @param {Array} config.filter
 * @returns
 */
async function getPlatformBudgeting(config) {
    const cacheKey = `platform_budgeting_${JSON.stringify(config)}`;

    const cacheEntry = cache.get(cacheKey);

    if (cacheEntry) {
        return cacheEntry;
    }

    const {
        dealerId,
        platform,
        monthlyBudget,
        filter,
    } = config;

    const today = moment().format('YYYY-MM-DD');
    const startOfMonth = moment().startOf('month').format('YYYY-MM-DD');

    const [monthlyCampaigns, todaysCampaigns] = await Promise.all([
        getPlatformCampaigns(startOfMonth, today, dealerId, platform, filter),
        getPlatformCampaigns(today, today, dealerId, platform, filter),
    ]);

    const adsetFetchingPromises = [];
    for (const campaign of monthlyCampaigns) {
        if (!campaign?.budget?.daily_budget && campaign?.budget?.lifetime_budget) {
            campaign.isLifetimeBudget = true;
        }

        if (campaign.budget.level === BUDGET_LEVEL_ADSET) {
            adsetFetchingPromises.push(
                getCampaignAdSets(dealerId, startOfMonth, today, platform, campaign.external_id).then(adsets => {
                    campaign.adsets = adsets; // Attach ad sets to the campaign
                }),
            );
        }
    }

    await Promise.all(adsetFetchingPromises);

    // We need to base daily budget of campaigns/ad sets that will be active on the remaining of the month
    const dailyBudget = monthlyCampaigns.reduce(reduceDailyBudget, 0);
    const spendToDate = monthlyCampaigns.reduce(reduceSpend, 0);
    const todaysSpend = todaysCampaigns.reduce(reduceSpend, 0);

    const budgetingData = calculateBudgeting({
        monthlyBudget,
        dailyBudget,
        spendToDate,
        todaysSpend,
    });

    const data = {
        campaigns: monthlyCampaigns,
        budgeting: budgetingData,
        hasSpend: Boolean(spendToDate),
    };

    cache.set(cacheKey, data);

    return data;
}

export default getPlatformBudgeting;
